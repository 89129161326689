import React, { useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import fullStack from "./images/full-stack.png"; 
import frontEnd from "./images/front-end.png"; 
import backEnd from "./images/back-end.png";
import reactNative from "./images/react-native.png";  
import "./trainingmodels.css";
 

const Section = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  scroll-snap-align: center;
  gap: 130px;
`;

const Container = styled.div`
  width: 1400px;
  display: flex;
  justify-content: space-between;
  margin-top: -70px;
  height: 600px !important;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    height: auto !important;
  }
`;

const Left = styled.div`
  flex: 1;
  display: flex;
    align-items: center;
    justify-content: center;
  @media only screen and (max-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: -103px;
  }
`;

const Right = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  @media only screen and (max-width: 768px) {
    align-items: center;
    text-align: center;
    margin-bottom: 30px;
  }
`;

const WhatWeDo = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const Button = styled.button`
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;



const TrainingModels = () => {
  const { t, i18n } = useTranslation();
  const {
    fsdTitle,
    fsdText,
    fsdInfo,
    fedTitle,
    fedText,
    fedInfo,
    bedTitle,
    bedText,
    bedInfo,
    rndTitle,
    rndText,
    rndInfo,
  } = t("academy");

  const {
    trainingBtn,  
  } = t("softwareTraining");
    const [modalIndex, setModalIndex] = useState(0);

const TrainingModelList = [
    {
        title: fsdTitle,
        image: fullStack,
        text: fsdText,
        info: fsdInfo,
        link: "https://www.alohadijitalakademi.com/full-stack-developer/"
    },
    {
        title: fedTitle,
        image: frontEnd,
        text: fedText,
        info: fedInfo,
        link: "https://www.alohadijitalakademi.com/front-end-developer-egitimi/"
    },
    {
        title: bedTitle,
        image: backEnd,
        text: bedText,
        info: bedInfo,
        link: "https://www.alohadijitalakademi.com/back-end-developer-egitimi/"
    },
    {
        title: rndTitle,
        image: reactNative,
        text: rndText,
        info: rndInfo,
        link: "https://www.alohadijitalakademi.com/react-native-egitimi/"
    }
];

    const handleModalChange = (index) => {
        setModalIndex(index);
    };

    return (
        <Section className="container py-5 flex flex-col">
            {TrainingModelList.map((model, index) => (
                <Container key={index}>
                    <Left>
                        <img src={model.image} alt={model.title} />
                    </Left>
                    <Right>
                        <h2 className="webTitle">{model.title}</h2>
                        <p className="wepDesc">{model.text}</p>
                        <WhatWeDo>
                            <ul className="web-list-items">
                                {model.info.map((infoItem, infoIndex) => (
                                    <>
                                        <li className="web-list-item" key={infoIndex}>
                                            <span className="web-list-icon">
                                                <i className="fa-solid fa-circle-check"></i>
                                            </span>
                                            <Button onClick={() => handleModalChange(infoIndex)} type="button">
                                                <span className="web-list-desc">{infoItem}</span>

                                            </Button>
                                        </li><hr className="web-hr" />
                                    </>
                                ))}
                            </ul>
                            
                        </WhatWeDo>
                        <div>
            <a
              href={model.link}
              target="_blank"
              rel="noopener noreferrer"
              type="button"
              class="btn"
            >
              <span className="elementor-button elementor-button-link">
                <span className="elementor-button-content-wrapper">
                  <span className="elementor-button-text">{trainingBtn}</span>
                  <span className="elementor-button-icon">
                    <i class="fa-solid fa-arrow-right arrowIcon"></i>
                  </span>
                </span>
              </span>
            </a>
          </div>
                    </Right>
                </Container>
            ))}
        </Section>
    );
};

export default TrainingModels;
