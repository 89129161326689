import "./navbar.css";
import { useEffect, useState } from "react";
import Languages from "../../languages/Languages";
import { Link } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next";

const Navbar = () => {
  const [clicked, setClicked] = useState(false);
  const { t } = useTranslation();
  const { navLine1, navLine2, navLine3, navLine4, navLine5, navLine6 } = t("navTitle");
  const {
    service1,
    service2,
    service3,
    service4,
    service5,
    service6,
    service7,
    service8,
    service9,
    service10,
    service11,
    service12,
  } = t("ServiceTitle");

  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const [services, setServices] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${backendUrl}api/services`);
        setServices(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [backendUrl]);

  const handleClick = () => {
    setClicked(!clicked);
  };

  return (
    <div className="content">
      <nav className="nav-container">
        <div className="logo-container">
          <Link to="/" className="logo">
            <img src="/images/logo-dijital.png" alt="" className="logo-img" />
          </Link>
        </div>
        <div className="nav-content">
          <ul id="navbar" className={clicked ? "#navbar active" : "#navbar"}>
            <li className="list">
              <Link onClick={handleClick} to="/">
                {navLine1}
              </Link>
            </li>
            <li className="dropdown">
              <p className="dropbtn navbarp">
                {navLine2}
                <i class="fa-solid fa-caret-down ms-1 fs-6"></i>
              </p>
              <div className="dropdown-content">
                <Link
                  onClick={handleClick}
                  to="/Özelleştirilmiş Web Uygulamaları"
                >
                  {service1}
                </Link>
                <Link onClick={handleClick} to="/Mobil Uygulama Geliştirme">
                  {service2}
                </Link>
                <Link onClick={handleClick} to="/Veri Analitiği ve Raporlama">
                  {service3}
                </Link>
                <Link
                  onClick={handleClick}
                  to="/Veri Tabanı Ve Sunucu Yönetimi"
                >
                  {service4}
                </Link>
                <Link onClick={handleClick} to="/A'dan Z'ye Yazılım Desteği">
                  {service5}
                </Link>
                <Link onClick={handleClick} to="/Kreatif Strateji">
                  {service6}
                </Link>
                <Link onClick={handleClick} to="/Marka Geliştirme">
                  {service7}
                </Link>
                <Link onClick={handleClick} to="/Sosyal Medya Yönetimi">
                  {service8}
                </Link>
                <Link onClick={handleClick} to="/Reklam Yönetimi">
                  {service9}
                </Link>
                <Link onClick={handleClick} to="/SEO ve ASO">
                  {service10}
                </Link>
                <Link onClick={handleClick} to="/Startup Mentörlüğü">
                  {service11}
                </Link>
                <Link onClick={handleClick} to="/Yazılım Eğitimi">
                  {service12}
                </Link>
              </div>
            </li>
            <li className="list">
              <Link onClick={handleClick} to="/aloha-dijital-akademi">
                {navLine6}
              </Link>
            </li>
            <li className="list">
              <Link onClick={handleClick} to="/ref">
                {navLine3}
              </Link>
            </li>
            <li className="list">
              <Link onClick={handleClick} to="/about">
                {navLine4}
              </Link>
            </li>
            <li className="list">
              <Link onClick={handleClick} to="/contact">
                {navLine5}
              </Link>
            </li>
          </ul>
          <div className="languages">
            <Languages />
          </div>
          <div id="mobile" onClick={handleClick}>
            <i
              id="bar"
              className={clicked ? "fas fa-times" : "fas fa-bars"}
            ></i>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
