import { motion, useAnimation } from "framer-motion";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { Link, useNavigate } from "react-router-dom";

const Project3 = () => {
  const ref = useRef(null);
  const controls = useAnimation(); // Burada kontrolcüyü tanımlıyoruz
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const { t } = useTranslation();
  const { proje4desc, projeTitle4, homeButton } = t("home");

  useEffect(() => {
    const handleScroll = () => {
      if (ref.current) {
        const elementTop = ref.current.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;
        if (elementTop < windowHeight * 0.9) {
          controls.start({ x: -305 }); // Kontrolcüyü burada kullanıyoruz
        } else {
          controls.start({ x: 500 }); // Kontrolcüyü burada kullanıyoruz
        }
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [controls]);

  return (
    <div className="row container">
      <div className="col-sm-12 col-md-9 pt-4 home-desc order-sm-last order-lg-first">
        <h3 className="project-title">{projeTitle4}</h3>
        <p className="desc">{proje4desc}</p>
        <Link
                  to="https://pdi.veriustechnology.com/"
                  target="_blank"
                  className="elementor-button elementor-button-link"
                >
                  <span className="elementor-button-content-wrapper">
                    <span className="elementor-button-text">{homeButton}</span>
                    <span className="elementor-button-icon">
                      <i class="fa-sharp fa-solid fa-angle-right arrowIcon"></i>
                    </span>
                  </span>
         </Link>
      </div>
      <motion.div
        ref={ref}
        animate={controls}
        initial={{ x: isMobile ? 200 : 300 }}
        transition={{ duration: isMobile ? 0.5 : 1.7, ease: "easeOut" }}
        className="col-sm-12 col-md-3 img-container3"
      >
        <img className="h-100" src="/images/pdiscreens.png" alt="" />
      </motion.div>
    </div>
  );
};

export default Project3;
