import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import TrainingModels from "../academy/TrainingModels";
import AkademiLogo from "./images/aloha-academy.png"
import "../references/references.css";
import { Canvas } from "@react-three/fiber";
import { MeshDistortMaterial, OrbitControls, Sphere } from "@react-three/drei";
import { Suspense, useEffect, useState } from "react";
import Carousel from "react-multi-carousel";


const AcademyPage = () => {
  const { t, i18n } = useTranslation();
  const {
    title,
    subtext,
    AcademyDesc,
   
  } = t("academy");

  const defaultAnimations = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
    },
  };

  // const { t, i18n } = useTranslation();
   const [centerImage, setCenterImage] = useState(t("refCenterImage"));
  // const {
  //   refTitle,
  //   refTitleMore,
  //   refDesc,
  //   refBtn,
  //   refTitle2,
  //   refTitleMore2,
  //   liveTitle,
  //   liveTitleDesc,
  //   liveTitle2,
  //   liveTitleDesc2,
  //   liveTitle3,
  //   liveTitleDesc3,
  //   liveTitle4,
  //   liveTitleDesc4,
  //   liveTitle5,
  //   liveTitleDesc5,
  //   liveTitle6,
  //   liveTitleDesc6,
  // } = t("references");

  const images = {
    en: {
      appHesap: "/images/acountIconEn.png",
      dinleyici: "/images/ListeningiconEn.png",
      konusmaci: "/images/speakerIconEn.png",
      coinAl: "/images/shopIconEn.png",
      kuponAl: "/images/couponIconEn.png",
      hediyeGonder: "/images/giftIconEn.png",
    },
    tr: {
      appHesap: "/images/appHesap.png",
      dinleyici: "/images/dinleyici-Img.png",
      konusmaci: "/images/konusmaci-img.png",
      coinAl: "/images/coinAl-img.png",
      kuponAl: "/images/kuponAl-img.png",
      hediyeGonder: "/images/hediyeGonder-img.png",
    },
  };

  const handleHover = (imageKey) => {
    const newImage = images[i18n.language][imageKey];
    setCenterImage(newImage);
  };

  useEffect(() => {
    setCenterImage(t("refCenterImage"));
  }, [i18n.language, t]);

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
      slidesToSlide: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  return (
    <section className="homeContant">
        <div className="row  refContainer mb-[150px]">
          <motion.div
            initial="hidden"
            variants={{
              hidden: { opacity: 0, y: 75 },
              visible: { opacity: 1, y: 0 },
            }}
            animate="visible"
            transition={{ duration: 0.5, delay: 0.25 }}
            className=" refhomeleft col-12 col-sm-12 col-md-6 position-relative"
          >
            <motion.h1
              initial="hidden"
              variants={{
                hidden: { opacity: 0, y: 75 },
                visible: { opacity: 1, y: 0 },
              }}
              animate="visible"
              transition={{ duration: 0.5, delay: 0.25 }}
              className="refTitle"
            >
              <font style={{ color: "#512AAD", fontWeight: "bold" }}>
                <font style={{ verticalAlign: "inherit" }}>
                  <font style={{ verticalAlign: "inherit" }}>{title}</font>
                </font>
              </font>
              <br />
              <font style={{ verticalAlign: "inherit" }}>
                <font style={{ verticalAlign: "inherit" }}>{subtext}</font>
              </font>
            </motion.h1>
            <motion.p
              initial="hidden"
              variants={{
                hidden: { opacity: 0, y: 75 },
                visible: { opacity: 1, y: 0 },
              }}
              animate="visible"
              transition={{ duration: 0.5, delay: 0.25 }}
              className="homeDesc refDesc"
            >
              {AcademyDesc}
            </motion.p>
            
          </motion.div>
          <motion.div
            animate={{ x: 60 }}
            initial={{ x: 800 }}
            transition={{ duration: 1.2, ease: "easeOut" }}
            className="col-12 col-sm-12 col-md-6 img-container ref-img-container"
          >
            <Canvas>
              <Suspense fallback={null}>
                <OrbitControls enableZoom={false} />
                <ambientLight intensity={3} />
                <directionalLight position={[3, 1, 3]} />
                <Sphere args={[1, 100, 200]} scale={2.8}>
                  <MeshDistortMaterial
                    color="#FFB013"
                    attach="material"
                    distort={0.4}
                    speed={3}
                    roughness={0}
                    metalness={0.2}                  
                  />
                </Sphere>
              </Suspense>
            </Canvas>
            <img
              className="homeImg2"
              src={AkademiLogo}
              alt="app insan img"
            />
          </motion.div>
        </div>

      <TrainingModels/>
    </section>
  );
};

export default AcademyPage;
