import React, { useState } from 'react';
import "./webprojects.css";
import ekonomiManset from "./projectImages/ekonomimanset.png";
import psmMag from "./projectImages/psmmag.png";
import sigortaGundem from "./projectImages/sigortagundem.png";
import genesis from "./projectImages/genesis.png";
import klasemlak from "./projectImages/klasemlak.png"
import amerikadanistekler from "./projectImages/amerikadanistekler.png"
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";



const WebProjects = () => {
    const { t } = useTranslation();
    const { pageTitle, title1, desc1, title2, desc2, title3, desc3, title4, desc4, title5, desc5, title6, desc6, pagelink } = t("webProjects");

    const projects = [
        {
            title: title1,
            img: ekonomiManset,
            link: "https://ekonomimanset.com/",
            summary: desc1,
        },
        {
            title: title2,
            img: psmMag,
            link: "https://www.psmmag.com/",
            summary: desc2,
        },
        {
            title: title3,
            img: sigortaGundem,
            link: "https://sigortagundem.com/",
            summary: desc3,
        },
        {
            title: title4,
            img: genesis,
            link: "https://www.genesishealthistanbul.com/",
            summary: desc4,
        },
        {
            title: title5,
            img: klasemlak,
            link: "https://www.klasemlak.com.tr/",
            summary: desc5,
        },
        {
            title: title6,
            img: amerikadanistekler,
            link: "https://amerikadanistekler.com/",
            summary: desc6,
        }
    ];
    
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [modalClosing, setModalClosing] = useState(false);


    const openModal = (img) => {
        setSelectedImage(img);
        setIsModalOpen(true);
        setModalClosing(false);
    };

    const closeModal = () => {
        setModalClosing(true);
        setTimeout(() => {
            setIsModalOpen(false);
            setSelectedImage(null);
        }, 300);
    };

    const handleClickOutside = (e) => {
        if (e.target === e.currentTarget) {
            closeModal();
        }
    };

    return (
        <div>
            <h3 className="project-title web-projects-title">{pageTitle}</h3>
        <div className='web-projects-container'>
            
        
            {projects.map((project, index) => (
                <div className='web-project-card' key={index}>
                    <div className='web-projects-image-container'>
                        <img
                            className='web-project-card-image'
                            src={project.img}
                            alt={project.title}
                            onClick={() => openModal(project.img)}
                        />
                        
                    </div>
                    <div className='web-project-card-content '>
                        <div>
                    <h3 className='web-project-card-title'>{project.title}</h3>
                    <p className='web-projects-summary'>{project.summary}</p>
                    </div>
                    <Link
                        to={project.link}
                        className="elementor-button elementor-button-link web-project-card-link"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <span className="elementor-button-content-wrapper">
                            <span className="elementor-button-text">{pagelink}</span>
                            <span className="elementor-button-icon">
                                <i className="fa-sharp fa-solid fa-angle-right arrowIcon"></i>
                            </span>
                        </span>
                    </Link>
                    </div>
                </div>
            ))}



            {isModalOpen && (
                <div
                    className={`web-projects-modal ${modalClosing ? 'closing' : 'opening'}`}
                    onClick={handleClickOutside}
                >
                    <div className="web-projects-modal-content">
                        <span className="close" onClick={closeModal}>&times;</span>
                        <img src={selectedImage} alt="Büyük resim" className="modal-image" />
                    </div>
                </div>
            )}
        </div>
        </div>
    );
}

export default WebProjects;
